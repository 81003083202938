.carousel {
  height: 100%;
  object-fit: contain;
  object-position: center;
  overflow: hidden;
}

.carousel-item {
  background-repeat: no-repeat;
}
