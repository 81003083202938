.homepage-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding: 1rem;

  background-color: var(--clr-primary-white);
}

.homepage-wrapper h5 {
  font-size: 1.2rem;
  font-weight: 500;
}

.carousel-container {
  height: 400px;
  overflow: hidden;
}

.build-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 1rem;
}

.price-guide {
  display: grid;
  gap: 1rem;
  padding: 1rem;
}

@media only screen and (min-width: 600px) {
  .homepage-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .carousel-container {
    height: 100%;
    grid-row-start: 1;
  }

  .price-guide {
    grid-row-start: 2;
    grid-column: span 2;
  }
}

@media only screen and (min-width: 992px) {
  .carousel-container {
    grid-row: span 2;
  }

  .price-guide {
    grid-row-start: 2;
    grid-column-start: 2;
  }
}
