.footer-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  padding: 1rem;
  border-bottom: 1px solid var(--clr-primary-black);
  background: radial-gradient(
    circle,
    var(--clr-secondary-dark-green) 60%,
    var(--clr-secondary-light-green)
  );
  color: var(--clr-primary-white);
}

.contact-details {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  font-size: 35px;
  text-decoration: none;
}

.social-link-youtube {
  color: #eb3223;
}

.social-link-twitter {
  color: #49a1eb;
}

@media only screen and (min-width: 600px) {
  .footer-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
